@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form

.hoverable:hover {
  cursor: pointer!important;
}
.hoverable-scale:hover {
  transform: scale(1.05);
  z-index: 100;
}
.text-gradient {
  background: -webkit-linear-gradient(#23253a, #7c687d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-tuito0{
  color: $tuito0;
}
.text-tuito1{
  color: $tuito1;
}
.text-tuito2{
  color: $tuito2;
}
.text-tuito3{
  color: $tuito3;
}
.text-tuito4{
  color: $tuito4;
}
.text-tuito5{
  color: $tuito5;
}
.bg-tuito0{
  background-color: $tuito0;
}
.bg-tuito1{
  background-color: $tuito1;
}
.bg-tuito2{
  background-color: $tuito2;
}
.bg-tuito3{
  background-color: $tuito3;
}
.bg-tuito4{
  background-color: $tuito4;
}
.bg-tuito5{
  background-color: $tuito5;
}